var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row books"
  }, [_c('div', {
    staticClass: "card mt-2 pt-2"
  }, [_c('h2', {
    staticClass: "title h2 text-center"
  }, [_vm._v(" Report Content")]), _c('div', {
    staticClass: "card-body row"
  }, [_c('div', {
    staticClass: "col-md-12 pt-2"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('span', {
    staticClass: "col-md-2"
  }, [_vm._v("Select Range")]), _c('div', {
    staticClass: "col-md-auto"
  }, [_c('v-date-picker', {
    attrs: {
      "mode": "date",
      "masks": _vm.masks,
      "max-date": new Date(),
      "is-range": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var inputValue = _ref.inputValue,
          inputEvents = _ref.inputEvents,
          isDragging = _ref.isDragging;
        return [_c('div', {
          staticClass: "rows"
        }, [_c('input', _vm._g({
          staticClass: "flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1",
          class: isDragging ? 'text-gray-600' : 'text-gray-900',
          attrs: {
            "max-date": new Date()
          },
          domProps: {
            "value": inputValue.start
          }
        }, inputEvents.start)), _c('span', {
          staticClass: "p-2"
        }, [_vm._v("to")]), _c('input', _vm._g({
          staticClass: "flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1",
          class: isDragging ? 'text-gray-600' : 'text-gray-900',
          attrs: {
            "max-date": new Date()
          },
          domProps: {
            "value": inputValue.end
          }
        }, inputEvents.end))])];
      }
    }]),
    model: {
      value: _vm.range,
      callback: function ($$v) {
        _vm.range = $$v;
      },
      expression: "range"
    }
  })], 1), _c('div', {
    staticClass: "col-md-auto"
  }, [_c('button', {
    staticClass: "btn btn-block btn-primary",
    on: {
      "click": function ($event) {
        return _vm.actionSearch();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })])])])]), _c('div', {
    staticClass: "col-md-12 shadow-sm p-1"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.show,
      "rounded": "sm"
    },
    on: {
      "shown": _vm.onShown,
      "hidden": _vm.onHidden
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-icon', {
          attrs: {
            "icon": "stopwatch",
            "font-scale": "3",
            "animation": "cylon"
          }
        }), _c('p', {
          attrs: {
            "id": "cancel-label"
          }
        }, [_vm._v("Please wait...")]), _c('b-button', {
          ref: "cancel",
          attrs: {
            "variant": "outline-danger",
            "size": "sm",
            "aria-describedby": "cancel-label"
          },
          on: {
            "click": function ($event) {
              _vm.show = false;
            }
          }
        }, [_vm._v(" Cancel ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('apexchart', {
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.chartOptionSingleSales,
      "series": _vm.SaleseriesSingle
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-md-6 shadow-sm mt-1 p-1"
  }, [_c('h4', {
    staticClass: "title text-center h4"
  }, [_vm._v(" Single ")]), _c('b-table', {
    attrs: {
      "sticky-header": "600px",
      "responsive": "sm",
      "show-empty": "",
      "striped": "",
      "hover": "",
      "fields": _vm.fields,
      "items": _vm.items
    },
    scopedSlots: _vm._u([{
      key: "head()",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(row.label) + " ")])];
      }
    }, {
      key: "cell(no)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + row.index + 1) + " ")];
      }
    }, {
      key: "cell(name)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "300px"
          }
        }, [_c('router-link', {
          attrs: {
            "to": {
              name: _vm.DetailItems.name,
              params: {
                id: row.item.item_id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(row.item ? row.item.item_name : '') + " ")])], 1)];
      }
    }, {
      key: "cell(image)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('img', {
          staticClass: "img-thumbnail cover",
          attrs: {
            "src": _vm.basePath + _vm.removeBucket(row.item.thumb_image_normal),
            "alt": row.item.item_name
          }
        })])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-md-6 shadow-sm mt-1 p-1"
  }, [_c('h4', {
    staticClass: "title text-center h4"
  }, [_vm._v(" Subscriptions ")]), _c('b-table', {
    attrs: {
      "sticky-header": "600px",
      "responsive": "sm",
      "busy": _vm.isSubLoading,
      "show-empty": "",
      "striped": "",
      "hover": "",
      "fields": _vm.colloumn,
      "items": _vm.subscription
    },
    scopedSlots: _vm._u([{
      key: "head()",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(row.label) + " ")])];
      }
    }, {
      key: "cell(no)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + row.index + 1) + " ")];
      }
    }, {
      key: "cell(name)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "300px"
          }
        }, [_vm._v(" " + _vm._s(row.item ? row.item.name : '') + " ")])];
      }
    }])
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }