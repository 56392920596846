<template>
  <div class="row books">
    <div class="card mt-2 pt-2">
      <h2 class="title h2 text-center"> Report Content</h2>
      <div class="card-body row">
        <div class="col-md-12 pt-2">
          <div class="form-group row">
            <span class="col-md-2">Select Range</span>
            <div class="col-md-auto">
              <v-date-picker
                v-model="range"
                mode="date"
                :masks="masks"
                :max-date="new Date()"
                is-range
              >
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <div class="rows">
                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                      :max-date="new Date()"
                    />

                    <span class="p-2">to</span>

                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      :max-date="new Date()"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="col-md-auto">
              <button class="btn btn-block btn-primary" @click="actionSearch()">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12 shadow-sm p-1">
          <b-overlay :show="show" rounded="sm" @shown="onShown" @hidden="onHidden">
            <apexchart
              type="bar"
              height="350"
              :options="chartOptionSingleSales"
              :series="SaleseriesSingle"
            />
          <template #overlay>
            <div class="text-center">
              <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
              <p id="cancel-label">Please wait...</p>
              <b-button
                ref="cancel"
                variant="outline-danger"
                size="sm"
                aria-describedby="cancel-label"
                @click="show = false"
              >
                Cancel
              </b-button>
            </div>
          </template>
          </b-overlay>
        </div>

        <div class="col-md-6 shadow-sm mt-1 p-1">
          <h4 class="title text-center h4"> Single </h4>
          <b-table 
            sticky-header="600px"
            responsive="sm"
            show-empty
            striped
            hover 
            :fields="fields"
            :items="items">
            <template #head()="row">
              <div class="text-nowrap">
                {{ row.label }}
              </div>
            </template>
            <template #cell(no)="row">
              {{ ( currentPage - 1 ) * perPage + row.index + 1 }}
            </template>
            <template #cell(name)="row">
              <div style="width: 300px;">
                <router-link :to="{ name: DetailItems.name, params: {id: row.item.item_id} }">
                  {{row.item ? row.item.item_name :'' }}
                </router-link>
              </div>
            </template>
            <template #cell(image)="row">
              <div class="text-nowrap">
                <img 
                  class="img-thumbnail cover" 
                  :src="basePath + removeBucket(row.item.thumb_image_normal)" 
                  :alt="row.item.item_name">
              </div>
            </template> 
          </b-table>
        </div>
        <div class="col-md-6 shadow-sm mt-1 p-1">
          <h4 class="title text-center h4"> Subscriptions </h4>
          <b-table 
            sticky-header="600px"
            responsive="sm"
            :busy="isSubLoading"
            show-empty
            striped
            hover 
            :fields="colloumn"
            :items="subscription">
            <template #head()="row">
              <div class="text-nowrap">
                {{ row.label }}
              </div>
            </template>
            <template #cell(no)="row">
              {{ ( currentPage - 1 ) * perPage + row.index + 1 }}
            </template>
            <template #cell(name)="row">
              <div style="width: 300px;">
                {{row.item ? row.item.name :'' }}
              </div>
            </template>
          </b-table>
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions} from 'vuex'
import { DetailItems } from '../../router/content';
import moment from "moment";
  export default {
    name: 'Subledger',
    components: {
    },
    data () {
      return {
        range: {
          start: moment().subtract(1,'M').format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
        masks: {
          input: "YYYY-MM-DD",
        },
        totalRows:0,
        perPage:10,
        currentPage:1,
        perPageSubscription:100,
        currentPageSubscription:1,
        totalRowsSubscription:0,
        basePath:'https://ebooks.gramedia.com/ebook-covers/',
        fields:[
          { key:'No', label: 'No'},
          { key:'name', label:'Name'},
          {key : 'image', label:'Cover Item'},
          { key:'total',label:'Total Sales'},
        ],
        chartOptionSingleSales: {},
        SaleseriesSingle: [{ data: [] ,name:"Sales" }],
        DetailItems,
        show: false,
        colloumn:[
          { key:'No', label: 'No'},
          { key:'name', label:'Name'},
          { key:'total',label:'Total Sales'},
        ],
      }
    },
    computed: {
      ...mapState({
        items: (state) => state.content.items,
        subscription: (state) => state.content.subscription,
        isLoading: (state) => state.content.isLoading,
        successMessage: (state) => state.content.successMessage,
        errorMessage: (state) => state.content.errorMessage,
        isSubLoading: (state) => state.content.isSubLoading,
        subSuccessMessage: (state) => state.content.subSuccessMessage,
        subErrorMessage: (state) => state.content.subErrorMessage,
      })
    },
    watch: {
      isLoading: function() {
        if(this.isLoading){
          this.show = true;
        } else {
          this.show = false;
        }
      },
      currentPage: function() {
        this.actionSearch()
      },
      items: function() {
        this.actionLoadChart()
      },
      $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'Report Content | Content Management System Ebooks Gramedia.com';
        }
      },
    },
    methods: {
      ...mapActions('content',['fetchSingle','fetchSubscription']),
      validateDate() {
        if(!this.range.start || !this.range.end){
           this.$swal({
            toast: "true",
            position: "top-end",
            icon: "error",
            title: "Date range is required",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return false;
        } else if (moment(this.range.start).isAfter(this.range.end)) {
          this.$swal({
            toast: "true",
            position: "top-end",
            icon: "error",
            title: "Date range is Invalid",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return false;
        } else {
          return true;
        }
      },
      actionLoadChart() {
        let data = this.items
        if(data.length > 0){
          let name = data.map(t => {
            return t.item_name;
          });
          let total = data.map(t => {
            return t.total;
          });
          this.SaleseriesSingle[0].data = total;
          this.chartOptionSingleSales = {
              plotOptions: {
                bar: {
                  horizontal: true
                }
              },
              dataLabels: {
                enabled: true,
                textAnchor: "start",
                style: {
                  colors: ["#fff"]
                },
                formatter: function(val) {
                  return val + " Sales";
                },
                offsetX: 0,
                dropShadow: {
                  enabled: true
                }
              },
              colors: ['#0060af'],
              fill: {
                colors:['#0060af'],
              },
              markers: {
                colors:['#43d14f'],
              },
              tooltip: {
                theme: "dark"
              },
              title: {
                text: `Popular Single Sales ${moment(this.range.start).format('YYYY MMM DD')} - ${moment(this.range.end).format('YYYY MMM DD')}` ,
                align: "left"
              },
              xaxis: {
                categories: name,
                align: "left"
              }
            };
        }
      },
      extractQuery (){
        let query  = this.$route.query
        if (query) {
          this.$router.push({ path: "content-report" , query: query});
          return {
            from: moment(query.from).format("YYYY-MM-DD"),
            to: moment(query.to).format("YYYY-MM-DD"),
            page: this.currentPage,
            limit: this.perPage,
            offer_type:'single'
          };
          
        }
        return {
          from: moment(this.range.start).format("YYYY-MM-DD"),
          to: moment(this.range.end).format("YYYY-MM-DD"),
          page: this.currentPage,
          limit: this.perPage,
          offer_type:'single'
        };
      },
      actionSearch(){
        let v = this.validateDate()
        if (v) {
          // do search
          this.$router.push({ path: "content-report" , query: {from: moment(this.range.start).format("YYYY-MM-DD"),to: moment(this.range.end).format("YYYY-MM-DD")}});
          this.showModal()
          this.fetchSingle({
            from: moment(this.range.start).format("YYYY-MM-DD"),
            to: moment(this.range.end).format("YYYY-MM-DD"),
            page: this.currentPage,
            limit: this.perPage,
            offer_type:'single'
          })
          this.fetchSubscription({
            from: moment(this.range.start).format("YYYY-MM-DD"),
            to: moment(this.range.end).format("YYYY-MM-DD"),
            page: this.currentPage,
            limit: this.perPage,
            offer_type:'subscription'
          })
        }
      },
      removeBucket(str) {
        if (str.substring(0, 6) == "images") {
          return str.substring(9);
        } else {
          return str;
        }
      },
      onShown() {
        this.$refs.cancel.focus()
      },
      onHidden() {
      },
      showModal() {
        this.show = true
      }
    }
  }
</script>
<style scoped>
.books {
  font-family: "Avenir Next","effra", Helvetica, Arial, sans-serif;
}
.cover {
  height: auto;
  width: 100px;
  padding:5px;
  margin: 5px 5px;
}
</style>